import Page from 'components/page';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Forms

// Parts
import Products from 'components/parts/products';
import DetailProd from 'components/parts/product/detailprod';
import DetailInfo from 'components/parts/product/detailinfo';
import DetailOrder from 'components/parts/product/detailorder';
import ProdPrice from 'components/parts/product/prodprice';
import ProdTocart from 'components/parts/product/prodtocart';
import ProdStock from 'components/parts/product/prodstock';
import Table from 'components/parts/table';
import Loader from 'components/parts/loader';

// Helpers
import { empty } from 'helpers/core';
import * as HPage from 'helpers/page';
import * as HUrl from 'helpers/url';
import {orderByStockAndPrice} from 'helpers/products';
import { countDiscount } from 'helpers/money';

// Context
import {useGlobal} from 'context/globalContext.js';
import {useProducts} from 'context/productsContext';
// Data
import * as DataProduct from 'data/productData';


const ProductPage = (props) => {
  // Hooks
  const {navigationSet} = useGlobal();
  const {products, productsReplaceType} = useProducts();
  const [syncProduct, setSyncProduct] = useState(false);
  const [syncProductsSimilar, setSyncProductsSimilar] = useState(false);

  const url = HUrl.fromGet(props);

  // After mount - load product
  useEffect( () => {
    setSyncProduct(undefined);
    const fetchData = async () => {
      const loadedProduct = await productLoad(url.id, url.slug);
      if (loadedProduct === null) {
        changeTitle();
      } else {
        changeTitle("PRONDO - "+ loadedProduct.product.code.code);
      }
    };
    fetchData();
  }, [props.match.params.id] );

  // Load similar -> after "product" loaded
  useEffect( async () => {
    if (! syncProduct) {
      return;
    }
    const fetchData = async () => {
      await similarLoad(url.id, url.slug);
    };
    fetchData();
  }, [syncProduct] );


  // GET
  const productLoad = async (id, slug) => {
    try {
      const product = await DataProduct.getProduct(id, slug);
      // If no product found
      if (product === null) {
        setSyncProduct(null);
      } else {
        productsReplaceType({ detail: [product.product] });
        processNavigationAfter(product.product);
        setSyncProduct(true);
      }
      return product;
    } catch(e) {
      return null;
    }
  };


  const similarLoad = async (id, slug) => {
    try {
      const similar = await DataProduct.getProducts(id, slug, {type:"similar", vehicle:products.vehicle.id}, "similar" );
      // If no product found
      if (similar === null || similar.products === null || similar.products.length === 0) {
        // no similar products found
      } else {
        productsReplaceType({ similar: similar.products });
      }
      setSyncProductsSimilar(true);
      return similar.products;
    } catch(e) {
      return null;
    }
  };


  // Process custom navigation

  const processNavigationAfter = (product) => {
    let out = HPage.buildNavigation();

    const categoriesCount = (product.categories[0]) ? product.categories[0].length : null;

    const prodNav = HPage.pageNew(
      (categoriesCount === null) ? 1 : categoriesCount + 1,
      [ `/product/${product.id}/${product.slug}`, `/produkt/${product.id}/${product.slug}` ],
      [ "home", "product" ],
      [ product.text.title, product.text.title ]
    );

    // Change "products" nav
    if (categoriesCount !== null) {
      for (let idx = 0; idx < categoriesCount; idx++) {
        const nav = product.categories[0][idx];
        out[idx+1] = HPage.pageNew(
          idx + 1,
          [ `/products/${nav.id}/${nav.slug}`, `/produkty/${nav.id}/${nav.slug}` ],
          [ "home", "products" ],
          [ nav.name, nav.name ]
        );
      }
    }

    if (categoriesCount === null) {
      out[1] = prodNav;
    } else {
      out.push( prodNav );
    }

    navigationSet( out );
  }


  // RENDER >>>

  if ( (! syncProduct) && (syncProduct !== null) ) {
    return (
      <Loader type="page" text="Načítám informace k produktu ..." />
    )
  }


  let product = null;
  // Select current object from globals
  for (const tmpProduct of products.all) {
    if ("detail" in tmpProduct.types) {
      product = tmpProduct;
      break;
    }
  }


  // Product not found //TODO: FORMAT IT
  if ( syncProduct === null || product === null ) {
    return (
      <div className="row-cols nobg">
        <main className="col-middle">

          <div className="txt-banner">
            <p>Tento produkt se nepodařilo nalézt.</p>
            <span>V případě, že jste sem zavítal(a) přes platný odkaz, <Link to={"/produkty"}>kontaktujte nás</Link> prosím.</span>
          </div>

        </main>
      </div>
    )
  }


  //let similar_products = orderByStockAndPrice( (! syncProductsSimilar) ? undefined : products.all.filter(item => "similar" in item.types ) );
  const similar_products = (! syncProductsSimilar) ? undefined : products.all.filter(item => "similar" in item.types );
  //let similar_category = orderByStockAndPrice( ( empty(product.categories) || empty(product.categories[0]) ) ? null : product.categories[0][ product.categories[0].length - 1 ] );
  const similar_category = ( empty(product.categories) || empty(product.categories[0]) ) ? null : product.categories[0][ product.categories[0].length - 1 ];

  return (
    <>

      <div className="row-cols nobg">
        <main className="col-middle noedge">

          <div id="row1">

            <DetailProd
              title={product.text.title}
              manufacturer={product.text.manufacturer}
              image={product.image.thumbnail}
              flags={product.flag}
              discount={countDiscount(product.price.recommended, product.price.price)}
            />

            <div>
              <DetailInfo
                code={product.code.code}
                our={product.code.our}
                ean={product.code.ean}
                manufacturer={product.text.manufacturer}
              />
              <div className="images">
              </div>
              <div className="pricestock">
                <ProdPrice priceTax={product.price.price} priceRefundableTax={product.price.fee.refundable} tax={product.price.tax} />
                <ProdTocart product={product} showRemove={true} />
                <ProdStock product={product} quantity={product.stock.stock} local={product.stock.local} delivery={product.stock.delivery} />
              </div>
              <DetailOrder
                info={[]}
                incart={product.cart.in}
                onstock={product.stock.stock}
                together={product.order.together}
              />
            </div>

          </div>


          <div id="row2">
            <p>
              {
                product.text.description &&
                  product.text.description.split('\n').map((item, key) => {
                    return <span key={key}>{item}<br/></span>
                  })
              }
            </p>

            <Table url={url} />
          </div>

        </main>
      </div>

      <div className="row-center nobg">
        <Products products={similar_products} banner_title="Podobné" banner_link={ (similar_category === null) ? null : `/produkty/${similar_category.id}/${similar_category.slug}` } banner_icon="is-sync-alt" />
      </div>

    </>
  )
};


class ProductPageClass extends Page {
  state = {
    sync_product : false,
    sync_products_similar : false,
    /*product : {
      id: 1,
      manufacturer: "Firma",
      title: "Testovací produkt Testovací abc produkt Testovací produkt",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae ante ultrices, dictum justo at, consequat sapien. Mauris volutpat a dui pellentesque pulvinar. Mauris rhoncus libero nisi, vitae venenatis purus porta nec. Vivamus ac sapien in diam egestas accumsan. Nulla ultrices, quam sodales facilisis consequat, risus ligula consequat lectus, sed vehicula arcu ante vel nulla. Maecenas convallis porttitor tortor non vulputate. Vivamus mollis eros eu nulla elementum tempus. Nulla at orci scelerisque, sagittis enim sed, lobortis enim. Sed ultricies tellus a urna malesuada, ac rhoncus lorem condimentum. Morbi urna magna, finibus a laoreet ac, facilisis et arcu. Donec sodales nisi porttitor lectus viverra efficitur. Duis porta gravida ornare.",
      image: "/assets/images/test-product-small.jpg",
      price: 1234.56,
      tax: 21,
      stock: 100,
      local: true,
      delivery: "1.1.2222",
      flags: [20, 55],
      ean: "546123987",
      code: "123ABC45"
    },
    products_similar : [
      {
        id: 1,
        manufacturer: "Firma",
        title: "Testovací produkt Testovací abc produkt Testovací produkt",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae ante ultrices, dictum justo at, consequat sapien. Mauris volutpat a dui pellentesque pulvinar. Mauris rhoncus libero nisi, vitae venenatis purus porta nec. Vivamus ac sapien in diam egestas accumsan. Nulla ultrices, quam sodales facilisis consequat, risus ligula consequat lectus, sed vehicula arcu ante vel nulla. Maecenas convallis porttitor tortor non vulputate. Vivamus mollis eros eu nulla elementum tempus. Nulla at orci scelerisque, sagittis enim sed, lobortis enim. Sed ultricies tellus a urna malesuada, ac rhoncus lorem condimentum. Morbi urna magna, finibus a laoreet ac, facilisis et arcu. Donec sodales nisi porttitor lectus viverra efficitur. Duis porta gravida ornare.",
        image: "/assets/images/test-product-small.jpg",
        price: 1234.56,
        tax: 21,
        stock: 100,
        local: true,
        delivery: "1.1.2021",
        flags: [20, 55]
      },
    ]*/
  }


  constructor(props) {
    super(props);
  }


  async componentDidMount() {
    const url = HUrl.fromGet(this.props);
    // TODO: NOT WORKING!!! >>>
    /*const product = await this.productLoad(url.id, url.slug);
    if (product !== null) {
      await this.similarLoad(url.id, url.slug);
    }*/

    // USED INSTEAD (TMP):
    const global = this.props.global;
    const product = await DataProduct.getProduct(url.id, url.slug);
    console.log( " FINISHED GET PRODUCT ");
    if (product !== null) {
      console.log( " DISPATCH ");
      this.props.dispatch({ type: 'PRODUCTS_SET', payload: dispatcherProductsReplaceType(global, {detail: [product.product]}) });
      console.log( " DISPATCHED ");
      this.setState({ sync_product : null });
      console.log( " SET STATE ");

      const products = await DataProduct.getProducts(url.id, url.slug, {type:"similar", vehicle:this.props.global.vehicle.id}, "similar" );
      this.props.dispatch({ type: 'PRODUCTS_SET', payload: dispatcherProductsReplaceType(global, {similar: products.products}) });
      this.setState({ sync_products_similar : true });
    }
  }


}

export default ProductPage;
