// Config
import configFile from 'config';


export function changeTitle(text) {
  if (text === undefined) {
    document.title = configFile.config.page.title;
  } else {
    document.title = text;
  }
};


// PAGE

export function pageNew(levelToReplace, urn, path, name, key = null) {
    let out = {
      path : path,
      urn: urn,
      name: name,
      customLink: false,
      level: levelToReplace   // Where to replace (for new naviagtion/merge)
    };
    if (key !== null) {
      out.key = key;
    }
    return out;
  };
  
  
  export function pageMerge(oldNav, newNav, maxLength) {
    if (oldNav === undefined) {
      console.log( "UNDEFINED OLD NAV" );
      return newNav;
    }
    if (newNav === undefined) {
      console.log( "UNDEFINED NEW NAV" );
      return oldNav;
    }
  
    let out = [];
  
    // Insert OLD navigation into OUT
    for (let idx = 0; idx < oldNav.length && (maxLength === undefined || idx < maxLength); idx++) {
      out.push(oldNav[idx]);
    }
  
    // Append from NEW navigation or replace OLD with NEW
    for (let idx = 0; idx < newNav.length; idx++) {
      const nav = newNav[idx];
  
      if ( (! ("level" in nav)) || (nav.level === null) || (nav.level >= oldNav.length) ) {
        // Append, if level in "new"
        if ( maxLength === undefined || out.length < maxLength ) {
          out.push(nav);
        }
      } else {
        // Replace in old
        if ( maxLength === undefined || nav.level < maxLength ) {
          out[nav.level] = nav;
        }
      }
    }
  
    return out;
  };
  
  
  export function pageCurrent() {
    const pages = configFile.config.pages;  // All pages
  
    // Current page
    let currentPage = window.location.pathname;
    if ( currentPage.length === 0 ) {
      const out = pages['home'];
      out.key = 'home';
      return out;
    }
    currentPage = currentPage.split(/[?#]/)[0];
  
    // Find exact
    for (let key in pages) {
      if ( pages[key].urn.indexOf( currentPage ) != -1 ) {
        const out = pages[key];
        out.key = key;
        return out;
      }
    }
  
    // Find by 1st "/.../"
    currentPage = currentPage.split('/');
    // Iterate all pages
    for (let key in pages) {
      // Iterate [arrays]
      for (let urn of pages[key].urn) {
        const current = urn.split('/');
        if ( current.length > 1 && current[1] === currentPage[1] ) {
          const out = pages[key];
          out.key = key;
          return out;
        }
      }
    }
  
    /*const out = pages['home'];
    out.key = 'home';
    return out;*/
    return null;
  };
  
  
  export function buildNavigation(currentPage, exceptions = false) {
    const pages = configFile.config.pages;
  
    if (currentPage === undefined) {
      currentPage = pageCurrent();
    }
  
    // If current page "null" -> nothing in navigation
    if (currentPage === null) {
      return [];
    }
  
    // Check, if current page exist in pages
    if (! currentPage.key in pages) {
      console.log("CURRENT PAGE NOT EXISTS! " + currentPage.key); // TODO: Log
      return [];
    }
  
  
    // EXCEPTIONS FOR DIFFERENT PAGES >>>
    if (exceptions) {
      switch (currentPage.key) {
        case "products":
          // TODO!
          return undefined;
        case "product":
          // TODO!
          break;
        default:
          break;
      }
    }
    // <<< EXCEPTIONS FOR DIFFERENT PAGES
  
  
    let out = [];
    // Iterate whole path of current page and add it to "out"
    for (let path of currentPage.path) {
      if (! path in pages) {
        console.log("PAGE NOT EXISTS! " + path); // TODO: Log
        continue;
      }
  
      const page = pages[path];
      page.key = path;
  
      // EXCEPTIONS FOR DIFFERENT PAGES >>>
      /*if (page.customLink) {
        switch (path) {
          case "products":
            // TODO!
            break;
          case "product":
            // TODO!
            break;
          default:
            break;
        }
      }*/
      // <<< EXCEPTIONS FOR DIFFERENT PAGES
  
      out.push( page );
    }
    // Append current page to the end
    out.push( currentPage );
  
    return out;
  };
