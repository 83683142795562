import {isset} from 'helpers/core';


// URL

export function url_base() {
  return window.location.origin;
}



// TODO: OLD, but some still used!!!

  // PARAMS
  
  // TODO: does not handle multi-valued keys, make: https://stackoverflow.com/a/21210643 .. https://stackoverflow.com/questions/5448545/how-to-retrieve-get-parameters-from-javascript
  export function paramGet(paramName) {
    var items = window.location.search.substr(1).split("&");
  
    for (var idx = 0; idx < items.length; idx++) {
      var idxFirst = items[idx].indexOf('=');
      var key;
      var value = undefined;
  
      // Divide into "key"+"value"
      if (idxFirst === -1) {
        // If there isn't any "=", consider as key only (isn't any value), return empty string
        key = items[idx];
        value = null;
      } else {
        key = items[idx].substring(0, idxFirst);
      }
  
      if ( key === paramName ) {
        if (value === undefined) {
          value = items[idx].substring(idxFirst + 1);
        }
        return decodeURIComponent(value);
      }
    }
  
    return undefined;
  }
  
  
  
  // GET
  
  export function fromGet( props ) {
    if (props === undefined) {
      return null;
    }
  
    const query = (props.match === undefined || props.match.params === undefined) ? undefined : props.match.params;
    const params = (props.location === undefined || props.location.search === undefined) ? undefined : parseUrlParams(props.location.search);
  
    return {
      id: (query === undefined || query.id === undefined) ? null : query.id,
      slug: (query === undefined || query.slug === undefined) ? null : query.slug,
      typ: (params === undefined || params.typ === undefined) ? null : params.typ,
      query: (query === undefined) ? null : query,
      params: (params === undefined) ? null : params,
    }
  }
  
  function parseUrlParams( url ) {
    let parsed = {};
    if (url != '') {
      url = url.substring(url.indexOf('?')+1);
      let p1 = url.split('&');
      p1.map(function(value) {
        let params = value.split('=');
        parsed[ decodeURI(params[0]) ] = decodeURI(params[1]);
      });
    }
    return parsed;
  }
  
  export function toGet(params) {
    let out = "";
  
    if (params) {
      for (let key in params) {
        if ( ! isset(key) ) { // Key not set
          continue;
        }
        if ( ! isset(params[key]) ) { // Value not set
          continue;
        }
        out += (out.length === 0) ? "?" : "&";  // Is first or not
        out += encodeURI(key) + "=" + encodeURI(params[key]);
      }
    }
  
    return out;
  }
  
  

// TODO -> not used in "toGet()"
function paramToGetArray(k, vArray) {
  /*let out = "";
  statesId.forEach( (value, idx) => {
    if ( out !== "" ) {
      out += "&";
    }
    out += `state[${idx}]=` + value;
  });*/
  let out = {};
  vArray.forEach( (value, idx) => {
    out[`${k}[${idx}]`] = value;
  });
  return out;
}


export function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "čďěňřšťůýžãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
  var to   = "cdenrstuyzaaaaaeeeeeiiiiooooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

  return str;
}


export function productTypeToEn(type = "category") {
  switch (type) {
    case undefined:
    case null:
    case "category":
    case "kategorie":
      return "category";
    case "universal":
    case "univerzalni":
      return "universal";
    case "search":
    case "hledat":
      return "search";
    case "interchangeable":
    case "zamenitelne":
      return "interchangeable";
    case "favorite":
    case "oblibene":
      return "favorite";
    case "sale":
    case "vyprodej":
      return "sale";
    default:
      return type;
  }
}
export function productTypeToCz(type = "kategorie") {
  switch (type) {
    case undefined:
    case null:
    case "category":
    case "kategorie":
      return "kategorie";
    case "universal":
    case "univerzalni":
      return "univerzalni";
    case "search":
    case "hledat":
      return "hledat";
    case "interchangeable":
    case "zamenitelne":
      return "zamenitelne";
    case "favorite":
    case "oblibene":
      return "oblibene";
    case "sale":
    case "vyprodej":
      return "vyprodej";
    default:
      return type;
  }
}


export function categoryLink(id, name, type) {
  switch (type) {
    case "universal":
    case "univerzalni":
      return "/produkty/" + id + "/" + slugify(name) + "?typ=" + productTypeToCz(type);
    case "category":
    case "kategorie":
    default:
      return "/produkty/" + id + "/" + slugify(name);
  }
}

export function productLinkSlugify(id, name) {
  return "/produkt/" + id + "/" + slugify(name);
}


export function isProduction() {
  return (
    window.location.href.startsWith("https://prondo.cz/")
    || window.location.href.startsWith("https://www.prondo.cz/")
    || window.location.href.startsWith("https://admin.prondo.cz/")
  );
}